<template>
  <div>
    <ui-section-header>
      <template v-slot:title>Subscription status canceled</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-hoverable">
      <tbody>
        <tr v-for="(subscription, index) in subscriptions" :key="index">
          <td>
            <div>{{ subscription.LocationName }}</div>
                {{ subscription.Name }}
          </td>
          <td>
            <a @click="goToLocation(subscription.LocationId)">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'share']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <Message
      v-if="!isLoading && subscriptions.length === 0"
      :message="'No subscription are canceled'"
      :type="'is-info'"
    />

    <transition name="fade" mode="out-in">
      <div v-if="isLoading">
        <ui-loader />
      </div>
    </transition>
  </div>
</template>

<script>
import subscriptionProvider from "@/providers/subscription";

const Message = () => import("@/components/UI/Message");

export default {
  components: {
    Message,
  },

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      subscriptions: [],
    };
  },

  computed: {},

  created() {
    this.getCanceledSubscriptions();
  },

  methods: {
    getCanceledSubscriptions() {
      this.subscriptions = [];

      if (!this.isLoading) {
        this.isLoading = true;

        subscriptionProvider.methods
          .getSubscriptions(this.channelId, this.locationId, new Date().getMonth() + 1, new Date().getFullYear())
          .then((response) => {
            if (response.status === 200) {
              this.subscriptions = response.data.filter(s=>s.IsCancelled===true);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    goToLocation(locationId) {
      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channelId, locationId: locationId },
      });
    },
  },
};
</script>

<style></style>
